import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import KrasnoyarskComponent from "../components/krasnoyarsk/krasnoyarsk.componet"

const HowScientificMonitoringNetworkOperatiesInOneOfTheMostPollutedCitiesInTheWorld = () => {
  return (
    <Layout>
      <KrasnoyarskComponent />
    </Layout>
  )
}

export default HowScientificMonitoringNetworkOperatiesInOneOfTheMostPollutedCitiesInTheWorld

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
