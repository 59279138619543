import React from "react"
import {useTranslation} from "react-i18next"
import {useI18next} from "gatsby-plugin-react-i18next";

import './krasnoyarsk.componet.less';

import krasnoyarskMain from "../../assets/img/krasnoyarsk/krasnoyarsk_main.jpg";
import krasnoyarskSmall from "../../assets/img/krasnoyarsk/krasnoyarsk_small.jpg";
import krasnoyarsk1 from "../../assets/img/krasnoyarsk/krasnoyarsk-1.jpg";
import krasnoyarsk2 from "../../assets/img/krasnoyarsk/krasnoyarsk-2.png";
import krasnoyarsk2M from "../../assets/img/krasnoyarsk/krasnoyarsk-2-mobile.jpg";
import Seo from "../seo";

const KrasnoyarskComponent = () => {
    const { t, i18n } = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div className="wrapper_page">
            <Seo description={t("metaDescriptionKrasnoyarsk")} lang={i18n.language} title={t("titleKrasnoyarsk")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="krasnoyarsk">
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span><span dangerouslySetInnerHTML={{__html: t("howScientificMonitoringNetwork")}} /></span>
                            <span
                                className="main-block__title-purple"> <span dangerouslySetInnerHTML={{__html: t("oneOfTheMostPollutedCities")}} /></span>
                        </div>
                        <div className="main-block__description">
                            <strong><span dangerouslySetInnerHTML={{__html: t("inTheYear2018Twenty")}} /></strong>
                        </div>
                        <div
                            className="main-block__description main-block__description--others">
                            <span dangerouslySetInnerHTML={{__html: t("theKeyObjectiveWastTo")}} />
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={krasnoyarskMain}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                    className="main-block__small-img"
                                    src={krasnoyarskSmall}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block simple-content">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span dangerouslySetInnerHTML={{__html: t("objective")}} />
                        </div>
                        <div className="simple-content__col-right">

                        </div>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div className="simple-content__description--block simple-content__description--big-size"><span dangerouslySetInnerHTML={{__html: t("theCityOfKrasnoyarsk")}} /></div>
                        </div>
                        <div className="simple-content__col-right">
                            <div className="simple-content__description--block simple-content__description--small-text"><span dangerouslySetInnerHTML={{__html: t("airQualityIssuehHas")}} /></div>
                            <div className="simple-content__description--block simple-content__description--small-text"><span dangerouslySetInnerHTML={{__html: t("theScientistsDecidedToDevelop")}} /></div>
                            <div className="simple-content__description--end-block simple-content__description--small-text"><span dangerouslySetInnerHTML={{__html: t("theKeyCriteriaToTheNetwork")}} /></div>
                        </div>
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border simple-content simple-content--2">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span dangerouslySetInnerHTML={{__html: t("solution")}} />
                        </div>
                        <div className="simple-content__col-right">
                        </div>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div className="simple-content__description--block simple-content__description--big-size"><span dangerouslySetInnerHTML={{__html: t("highDensityNetworkBasedOnGreat")}} /></div>
                        </div>
                        <div className="simple-content__col-right">
                        </div>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block simple-content simple-content__wtu small-border-top-ipad">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span dangerouslySetInnerHTML={{__html: t("calibration")}} />
                                </div>
                                <div className="simple-content__description--block simple-content__description--small-text">
                                    <span dangerouslySetInnerHTML={{__html: t("ofMonitorsAreLocatedInThe")}} />
                                </div>
                            </div>
                        </div>
                        <div className="simple-content--img-mobile display-block-mobile">
                            <img alt="" src={krasnoyarsk1}/>
                        </div>
                        <div className="borders-block__hr" />
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div className="borders-block__col--title"><span dangerouslySetInnerHTML={{__html: t("mapping")}} />
                                </div>
                                <div className="simple-content__description--block simple-content__description--small-text">
                                    <span dangerouslySetInnerHTML={{__html: t("theMonitorsHaveBeen")}} />
                                </div>
                            </div>
                        </div>
                        <div className="simple-content--img-mobile display-block-mobile simple-content--img-mobile-end">
                            <img alt="" src={krasnoyarsk2M}/>
                        </div>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block display-none-mobile simple-content">
                    <div className="simple-content__img">
                        <div className="simple-content__col-left simple-content__col-left--img">
                            <img alt="" src={krasnoyarsk1}/>
                        </div>
                        <div className="simple-content__col-right simple-content__col-right--img">
                            <img alt="" src={krasnoyarsk2}/>
                        </div>
                    </div>
                </div>

                <div className="wrapper_block wrapper_block__block simple-content wrapper_block__block--no-border">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span dangerouslySetInnerHTML={{__html: t("results")}} />
                        </div>
                        <div className="simple-content__col-right" />
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div className="simple-content__description--block simple-content__description--small-text"><strong><span dangerouslySetInnerHTML={{__html: t("highEquipmentDensityAndData")}} /></strong></div>
                            <div className="simple-content__description--block simple-content__description--small-text">
                                <ul>
                                    <li dangerouslySetInnerHTML={{__html: t("investigatePollutionTrends")}} />
                                    <li dangerouslySetInnerHTML={{__html: t("provideDimensionalAndTiming")}} />
                                    <li dangerouslySetInnerHTML={{__html: t("studyPMConcentrationSpots")}} />
                                    <li dangerouslySetInnerHTML={{__html: t("tracePollutionDispersionDynamics")}} />
                                    <li dangerouslySetInnerHTML={{__html: t("analyzeReferenceWith")}} />
                                </ul>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div className="simple-content__description--block simple-content__description--small-text"><strong> <span dangerouslySetInnerHTML={{__html: t("moreoverCityAirMonitorsFirstEver")}} /></strong></div>
                            <div className="simple-content__description--block simple-content__description--small-text">
                                <span dangerouslySetInnerHTML={{__html: t("theDataHelpedToIdentifyPollution")}} />
                            </div>
                            <div className="simple-content__description--block simple-content__description--small-text">
                                <span dangerouslySetInnerHTML={{__html: t("anotherCaseStudyHelpedToaAnalyze")}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

KrasnoyarskComponent.propTypes = {}

KrasnoyarskComponent.defaultProps = {}

export default KrasnoyarskComponent
